.online {
    background-color: green;
    color: #fff;
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 5px;
}
.offline{
    background-color: red;
    color: #fff;
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 5px;
    animation: blinkingBackground 1s infinite;
}
@keyframes blinkingBackground{
    0%		{ background-color: #ff0000;}
    25%		{ background-color: #f30e0e;}
    75%		{ background-color: #f2dede;}
    100%	{ background-color: #ffffff;}
}