.signup-head {
    text-align: center;
    font-size: 18px;
    color: #1d1d1d;
}

.terms-text {
    font-size: 13px;
    color: #474747; 
    margin-left: 5px;
}

.input-field-confirmation {
    width: 100%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #236A80;
    padding-left: 10px;
    text-align: center;
    
    
}

.link {
    text-decoration: none;
}